.root {
  width: 100%;
  border-collapse: collapse;
}

.clickable {
  cursor: pointer;
}

.row:hover,
.clickableRow:hover {
  background-color: var(--color-gray-100);
}

.row .cell:first-child,
.clickableRow .cell:first-child {
  padding-left: 16px;
}

.row .cell:last-child,
.clickableRow .cell:last-child {
  padding-right: 16px;
}

.clickableRow {
  cursor: pointer;
}

.cell {
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: inset 0 -1px #e3e8ee;
  
}

thead .row .cell {
  font-weight: 400;
  background-color: var(--color-blue-50);
  text-align: left;
}
